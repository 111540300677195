/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GroupRegistration } from '../models/GroupRegistration';
import type { GroupRegistrationCreate } from '../models/GroupRegistrationCreate';
import type { GroupRegistrationUpdate } from '../models/GroupRegistrationUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class GroupRegistrationsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List
     * @param authorization
     * @returns GroupRegistration Successful Response
     * @throws ApiError
     */
    public listGroupRegistrationsGet(
        authorization?: string,
    ): CancelablePromise<Array<GroupRegistration>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/group-registrations/',
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create
     * @param requestBody
     * @param authorization
     * @returns GroupRegistration Successful Response
     * @throws ApiError
     */
    public createGroupRegistrationsPost(
        requestBody: GroupRegistrationCreate,
        authorization?: string,
    ): CancelablePromise<GroupRegistration> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/group-registrations/',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get
     * @param uuid
     * @param authorization
     * @returns GroupRegistration Successful Response
     * @throws ApiError
     */
    public getGroupRegistrationsUuidGet(
        uuid: string,
        authorization?: string,
    ): CancelablePromise<GroupRegistration> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/group-registrations/{uuid}',
            path: {
                'uuid': uuid,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Group Registration
     * @param uuid
     * @param requestBody
     * @param authorization
     * @returns GroupRegistration Successful Response
     * @throws ApiError
     */
    public updateGroupRegistrationGroupRegistrationsUuidPut(
        uuid: string,
        requestBody: GroupRegistrationUpdate,
        authorization?: string,
    ): CancelablePromise<GroupRegistration> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/group-registrations/{uuid}',
            path: {
                'uuid': uuid,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete
     * @param uuid
     * @param authorization
     * @returns void
     * @throws ApiError
     */
    public deleteGroupRegistrationsUuidDelete(
        uuid: string,
        authorization?: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/group-registrations/{uuid}',
            path: {
                'uuid': uuid,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Approve
     * @param uuid
     * @param authorization
     * @returns any Successful Response
     * @throws ApiError
     */
    public approveGroupRegistrationsUuidApprovePost(
        uuid: string,
        authorization?: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/group-registrations/{uuid}/approve',
            path: {
                'uuid': uuid,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Renew
     * @param uuid
     * @param authorization
     * @returns any Successful Response
     * @throws ApiError
     */
    public renewGroupRegistrationsUuidRenewPost(
        uuid: string,
        authorization?: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/group-registrations/{uuid}/renew',
            path: {
                'uuid': uuid,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
