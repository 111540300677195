import { DataProvider } from 'ra-core';
import openAPI from '../../services/openapi/openAPI';
import { sortFactory } from '../../utils/sort';

const getSortMethod = (field: string) => {
  switch (field) {
    case 'uuid':
      return sortFactory('string', field);
    case 'ipv4':
      return sortFactory('string', field);
    case 'ipv6':
      return sortFactory('string', field);
    case 'enabled':
      return sortFactory('boolean', field);
    case 'nextsrv':
      return sortFactory('string', field);
    case 'pxefile':
      return sortFactory('string', field);
    case 'annotation':
      return sortFactory('string', field);
    case 'created_by':
      return sortFactory('string', field);
    case 'administrators':
      return sortFactory('string', field);
    case 'approved':
      return sortFactory('boolean', field);
    case 'period_id':
      return sortFactory('number', field);
    case 'group_id':
      return sortFactory('number', field);
    case 'justification':
      return sortFactory('string', field);
    default:
      return sortFactory('string', field);
  }
};

export default {
  // TODO: Implement paging, filtering and ordering
  getList: async (resource, params) => {
    const res =
      await openAPI.RegService.groupRegistrations.listGroupRegistrationsGet();

    const data = res.map((r) => ({
      ...r,
      id: r.uuid,
      administrators: r.administrators.map((a) => ({ schacc: a })),
      annotation: JSON.stringify(r.annotation),
    }));

    data.sort(getSortMethod(params.sort.field));
    if (params.sort.order === 'DESC') {
      data.reverse();
    }

    const filteredData = data.filter((obj) => {
      if (!params.filter.q) {
        return true;
      }
      // Partially match in all object values
      const values = Object.values(obj);
      const match = values.some((value) =>
        JSON.stringify(value)
          .toUpperCase()
          .includes(String(params.filter.q).toUpperCase()),
      );
      return match;
    });

    return {
      data: filteredData.slice(
        (params.pagination.page - 1) * params.pagination.perPage,
        params.pagination.page * params.pagination.perPage,
      ),
      total: filteredData.length,
    };
  },

  getOne: async (resource, params) => {
    const res =
      await openAPI.RegService.groupRegistrations.getGroupRegistrationsUuidGet(
        String(params.id),
      );

    return {
      data: {
        ...res,
        id: res.uuid,
        administrators: res.administrators.map((a) => ({ schacc: a })),
        annotation: JSON.stringify(res.annotation),
      },
    };
  },

  getMany: async () =>
    new Promise(() => {
      console.log('getMany');
    }),

  getManyReference: async () =>
    new Promise(() => {
      console.log('getManyReference');
    }),

  update: async (resource, params) => {
    const res =
      await openAPI.RegService.groupRegistrations.updateGroupRegistrationGroupRegistrationsUuidPut(
        String(params.id),
        {
          ...params.data,
          annotation: JSON.parse(params.data.annotation),
          administrators: params.data.administrators.map((a: any) => a.schacc),
        },
      );

    return {
      data: {
        ...res,
        id: res.uuid,
        annotation: JSON.stringify(res.annotation),
        administrators: res.administrators.map((a) => ({ schacc: a })),
      },
    };
  },

  updateMany: async () =>
    new Promise(() => {
      console.log('updateMany');
    }),

  create: async (resource, params) => {
    const res =
      await openAPI.RegService.groupRegistrations.createGroupRegistrationsPost({
        ipv4: params.data.ipv4,
        group_id: params.data.group_id,
        justification: params.data.justification,
        administrators: params.data.administrators.map((a: any) => a.schacc),
      });

    return {
      data: {
        ...res,
        id: res.uuid,
        annotation: JSON.stringify(res.annotation),
        administrators: res.administrators.map((a) => ({ schacc: a })),
      },
    };
  },

  delete: async (resource, params) => {
    const res =
      await openAPI.RegService.groupRegistrations.deleteGroupRegistrationsUuidDelete(
        String(params.id),
      );

    return {
      data: res,
    };
  },

  deleteMany: async () =>
    new Promise(() => {
      console.log('deleteMany');
    }),
} as DataProvider;
