/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomizedPage_Zone_ } from '../models/CustomizedPage_Zone_';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class V2ZonesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List Zones
     * @param orderBy Order by field. Example: `name` or `-name` for descending order
     * @param id
     * @param name
     * @param schOnly
     * @param versionGte
     * @param versionLte
     * @param masterDns
     * @param rname
     * @param refresh
     * @param refreshGte
     * @param refreshLte
     * @param retry
     * @param retryGte
     * @param retryLte
     * @param expire
     * @param expireGte
     * @param expireLte
     * @param ttl
     * @param ttlGte
     * @param ttlLte
     * @param page
     * @param size
     * @param authorization
     * @returns CustomizedPage_Zone_ Successful Response
     * @throws ApiError
     */
    public listZones(
        orderBy: string = 'id',
        id?: number,
        name?: string,
        schOnly?: boolean,
        versionGte?: number,
        versionLte?: number,
        masterDns?: string,
        rname?: string,
        refresh?: number,
        refreshGte?: number,
        refreshLte?: number,
        retry?: number,
        retryGte?: number,
        retryLte?: number,
        expire?: number,
        expireGte?: number,
        expireLte?: number,
        ttl?: number,
        ttlGte?: number,
        ttlLte?: number,
        page: number = 1,
        size: number = 50,
        authorization?: string,
    ): CancelablePromise<CustomizedPage_Zone_> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/zones',
            headers: {
                'authorization': authorization,
            },
            query: {
                'order_by': orderBy,
                'id': id,
                'name': name,
                'sch_only': schOnly,
                'version__gte': versionGte,
                'version__lte': versionLte,
                'master_dns': masterDns,
                'rname': rname,
                'refresh': refresh,
                'refresh__gte': refreshGte,
                'refresh__lte': refreshLte,
                'retry': retry,
                'retry__gte': retryGte,
                'retry__lte': retryLte,
                'expire': expire,
                'expire__gte': expireGte,
                'expire__lte': expireLte,
                'ttl': ttl,
                'ttl__gte': ttlGte,
                'ttl__lte': ttlLte,
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
