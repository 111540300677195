import {
  Labeled,
  SimpleForm,
  TextField,
  TextInput,
  DateTimeInput,
  Create,
  useTranslate,
  ListButton,
  NumberInput,
  BooleanInput,
  SelectInput,
} from 'react-admin';
import { Alert, Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { recordTypeInputChoices } from '../../../utils/record';

const DnsCreate = () => {
  const translation = useTranslate();
  return (
    <Create
      title={<span>{translation('resources.dns_record.create_title')}</span>}
      component="div"
    >
      <Box maxWidth="50em">
        <Card>
          <ListButton sx={{ marginTop: '1rem', marginLeft: '1rem' }} />
          <SimpleForm
            defaultValues={{
              ttl: 900,
              type: 'A',
              approved: true,
            }}
          >
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={1}>
                  <Labeled source="Id">
                    <TextField
                      source="id"
                      fontSize="1.4rem"
                      marginLeft=".6rem"
                    />
                  </Labeled>
                </Grid>
                <Grid item xs={12} md={7}>
                  <TextInput
                    source="reg_uuid"
                    fullWidth
                    helperText="resources.dns_record.fields.reg_uuid.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <NumberInput
                    source="zone_id"
                    fullWidth
                    isRequired
                    helperText="resources.dns_record.fields.zone_id.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput
                    source="name"
                    fullWidth
                    isRequired
                    helperText="resources.dns_record.fields.name.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput
                    source="value"
                    fullWidth
                    isRequired
                    helperText="resources.dns_record.fields.value.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <SelectInput
                    label="type"
                    source="type"
                    isRequired
                    choices={recordTypeInputChoices}
                    helperText="resources.dns_record.fields.type.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <BooleanInput
                    source="approved"
                    fullWidth
                    isRequired
                    helperText="resources.dns_record.fields.approved.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <NumberInput
                    source="ttl"
                    fullWidth
                    helperText="resources.dns_record.fields.ttl.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <BooleanInput
                    source="primary_hostname"
                    fullWidth
                    helperText="resources.dns_record.fields.primary_hostname.helperText"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </SimpleForm>
        </Card>
      </Box>
    </Create>
  );
};

export default DnsCreate;
